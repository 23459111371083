// src/components/CentralLoading.js
import React from 'react';
//import './CentralLoading.css'; // Fichier CSS que nous allons créer ensuite

const CentralLoading = React.memo(({ isLoading }) => {
  if (!isLoading) return null;

  return (
    <div className="central-loading-overlay">
      <div className="central-spinner"></div>
      <h4>Chargement en cours...</h4>
    </div>
  );
});

export default CentralLoading;
  