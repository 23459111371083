// src/services/blockchainService.js
import Web3 from 'web3';
import AngelusToken from '../contracts/AngelusToken.json';
import AngelusNFT from '../contracts/AngelusNFT.json';

export let web3;
export let angelusToken;
export let angelusNFT;

if (window.ethereum) {
  web3 = new Web3(window.ethereum);
} else {
  console.error('MetaMask non détecté. Veuillez installer MetaMask.');
  web3 = null;
}

export const connectWallet = async () => {
  if (web3) {
    try {
      const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
      const account = accounts[0];
      return account;  // Renvoie l'adresse du compte connecté
    } catch (error) {
      console.error('L’utilisateur a refusé l’accès à MetaMask', error);
      return null;
    }
  } else {
    window.alert('Veuillez installer MetaMask pour utiliser cette application.');
    return null;
  }
};

export const loadContracts = async () => {
  const networkId = await web3.eth.net.getId();

  const angelusTokenData = AngelusToken.networks[networkId];
  if (angelusTokenData) {
    angelusToken = new web3.eth.Contract(AngelusToken.abi, angelusTokenData.address);
  } else {
    window.alert('Contrat AngelusToken non déployé sur le réseau détecté.');
  }

  const angelusNFTData = AngelusNFT.networks[networkId];
  if (angelusNFTData) {
    angelusNFT = new web3.eth.Contract(AngelusNFT.abi, angelusNFTData.address);
  } else {
    window.alert('Contrat AngelusNFT non déployé sur le réseau détecté.');
  }
};

// getBalance prend maintenant le paramètre account explicitement
export const getBalance = async (account) => {
  if (!angelusToken || !account) return '0';
  try {
   // console.log("Récupération du solde pour le compte :", account);
    const tokenBalance = await angelusToken.methods.balanceOf(account).call();
  //  console.log("Solde brut du compte :", tokenBalance);
    return web3.utils.fromWei(tokenBalance, 'ether');
  } catch (error) {
    console.error('Erreur lors de la récupération du solde:', error);
    return '0';
  }
};

// getNFTs prend également account comme paramètre
export const getNFTs = async (account) => {
  if (!angelusNFT || !account) return [];
  try {
    const nftBalance = await angelusNFT.methods.balanceOf(account).call();
    const nfts = [];
    for (let i = 0; i < nftBalance; i++) {
      const tokenId = await angelusNFT.methods.tokenOfOwnerByIndex(account, i).call();
      const characterData = await angelusNFT.methods.getCharacter(tokenId).call();
      nfts.push({ tokenId, ...characterData });
    }
    return nfts;
  } catch (error) {
    console.error('Erreur lors de la récupération des NFTs:', error);
    return [];
  }
};
