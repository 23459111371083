import React, { useEffect, useReducer, useCallback } from 'react';
import { connectWallet, loadContracts, getBalance, getNFTs, web3 } from './services/blockchainService';
import CreateCharacter from './components/CreateCharacter';
import DetailCharacter from './components/DetailCharacter';
import CentralLoading from './components/CentralLoading';
import axios from 'axios';
import { url_backend } from './config';

// Ajoutez un écouteur de changement d'état MetaMask
const addMetaMaskListeners = (handleAccountChanged) => {
  if (window.ethereum) {
    window.ethereum.removeListener('accountsChanged', handleAccountChanged);
    window.ethereum.on('accountsChanged', handleAccountChanged);
    window.ethereum.on('chainChanged', () => window.location.reload());
  }
};

// Initial state for useReducer
const initialState = {
  account: '',
  balance: '0',
  nftBalance: '0',
  generateCount: 5,
  characterMinted: false,
  character: null,
  nfts: [],
  showModal: false,
  isLoading: false,
  currentIndex: 0,
  selectedNft: null,
  isAccountMenuOpen: false,
  cryptoTooltipVisible: false,
  playerId: null,
  isPlayer: false,
  isPlayerSelection: false,
  isCharacter: false,
};

// Reducer function to manage the state changes
const reducer = (state, action) => {
  switch (action.type) {
    case 'SET_ACCOUNT':
      return { ...state, account: action.payload };
    case 'SET_BALANCE':
      return { ...state, balance: action.payload };
    case 'SET_NFT_BALANCE':
      return { ...state, nftBalance: action.payload };
    case 'SET_GENERATE_COUNT':
      return { ...state, generateCount: action.payload };
    case 'SET_CHARACTER_MINTED':
      return { ...state, characterMinted: action.payload };
    case 'SET_CHARACTER':
      return { ...state, character: action.payload };
    case 'SET_NFTS':
      return { ...state, nfts: action.payload };
    case 'SET_SHOW_MODAL':
      return { ...state, showModal: action.payload };
    case 'SET_IS_LOADING':
      return { ...state, isLoading: action.payload };
    case 'SET_CURRENT_INDEX':
      return { ...state, currentIndex: action.payload };
    case 'SET_SELECTED_NFT':
      return { ...state, selectedNft: action.payload };
    case 'TOGGLE_ACCOUNT_MENU':
      return { ...state, isAccountMenuOpen: !state.isAccountMenuOpen };
    case 'SET_CRYPTO_TOOLTIP_VISIBLE':
      return { ...state, cryptoTooltipVisible: action.payload };
    case 'SET_PLAYER_ID':
      return { ...state, playerId: action.payload };
    case 'SET_IS_PLAYER':
      return { ...state, isPlayer: action.payload };
    case 'SET_IS_PLAYER_SELECTION':
      return { ...state, isPlayerSelection: action.payload };
    case 'SET_IS_CHARACTER':
      return { ...state, isCharacter: action.payload };
    default:
      return state;
  }
};

function App() {
  const [state, dispatch] = useReducer(reducer, initialState);

  // Fonction pour récupérer les données complètes des NFTs et les compléter avec le backend
  const fetchCompleteNftData = async (userNfts) => {
    try {
      const completeNfts = await Promise.all(
        userNfts.map(async (nft) => {
          const response = await axios.get(`${url_backend}/characters/${nft.tokenId}`);
          return { ...nft, ...response.data };
        })
      );
      dispatch({ type: 'SET_NFTS', payload: completeNfts });
    } catch (error) {
      console.error('Erreur lors de la récupération des informations supplémentaires des NFTs:', error);
    }
  };

// Fonction pour charger les données de la blockchain et les synchroniser avec le backend
const loadBlockchainAndBackendData = useCallback(async (connectedAccount) => {
  dispatch({ type: 'SET_IS_LOADING', payload: true });

  try {
    await loadContracts();

    // Récupérer les données de la blockchain pour le compte connecté
    const [tokenBalance, userNfts] = await Promise.all([
      getBalance(connectedAccount),  // Passe le compte ici
      getNFTs(connectedAccount),     // Idem ici
    ]);

    console.log("Solde d'Angelus du compte " + connectedAccount + " : " + tokenBalance);

    // Mettre à jour le state avec les nouvelles données
    dispatch({ type: 'SET_BALANCE', payload: tokenBalance });
    dispatch({ type: 'SET_NFT_BALANCE', payload: userNfts.length });
    dispatch({ type: 'SET_NFTS', payload: userNfts });

    // Récupérer les données du backend
    const response = await axios.post(`${url_backend}/players/infosPlayer`, { wallet_address: connectedAccount });

    if (response.data) {
      console.log(response.data);
      const { player_id, isPlayer, isPlayerSelection, isCharacter} = response.data;
      dispatch({ type: 'SET_PLAYER_ID', payload: player_id });
      dispatch({ type: 'SET_IS_PLAYER', payload: isPlayer });
      dispatch({ type: 'SET_IS_PLAYER_SELECTION', payload: isPlayerSelection });
      dispatch({ type: 'SET_IS_CHARACTER', payload: isCharacter });

      // Synchroniser les NFTs récupérés avec le backend
      if (userNfts.length > 0) await fetchCompleteNftData(userNfts);
     // console.log(tokenBalance)
      // Mettre à jour le backend avec la balance si différente
      //console.log(response.data.angelus_balance)
      //console.log(tokenBalance)
      if (tokenBalance !== response.data.angelus_balance) {
        await axios.post(`${url_backend}/players/updateBalance`, {
          wallet_address: connectedAccount,
          angelus_balance: tokenBalance,
        });
      }


      if (isCharacter) {
        dispatch({ type: 'SET_SELECTED_NFT', payload: response.data.character });
      }
      
      // Si un personnage est sélectionné, le mettre à jour dans le state
      if (isPlayerSelection) {
        
      const selectedNft = response.data.playerSelection;
      // Étape 2 : Récupérer la liste des NFTs du joueur depuis le backend
      const userBackendNfts = await fetchNftsFromBackend(player_id);
      console.log(userBackendNfts)  
      const selectedNftIndex = userBackendNfts.findIndex(nft => nft.tokenId === selectedNft.character_id);

        console.log(selectedNftIndex)

      }
    }
  } catch (error) {
    console.error('Erreur lors du chargement des données:', error);
  } finally {
    dispatch({ type: 'SET_IS_LOADING', payload: false });
  }
}, []);

  const fetchNftsFromBackend = async (playerId) => {
    try {
      const response = await axios.get(`${url_backend}/characters/${playerId}/nfts`);
      const nfts = response.data;

      // Étape 1: Log des NFTs récupérés du backend
      console.log("NFTs récupérés du backend :", nfts);

      return nfts;
    } catch (error) {
      console.error("Erreur lors de la récupération des NFTs depuis le backend :", error);
      return [];
    }
  };

  // Gérer la connexion au wallet
  const handleConnectWallet = useCallback(async () => {
    if (!web3) {
      window.alert('Veuillez installer MetaMask pour utiliser cette application.');
      return;
    }

    const connectedAccount = await connectWallet();
    if (connectedAccount) {
      dispatch({ type: 'SET_ACCOUNT', payload: connectedAccount });
      await loadBlockchainAndBackendData(connectedAccount);
    }
  }, [loadBlockchainAndBackendData]);

  // Gérer les changements de compte MetaMask
  const handleAccountChanged = useCallback(
    async (accounts) => {
      if (accounts.length > 0) {
        dispatch({ type: 'SET_ACCOUNT', payload: accounts[0] });
  
        // Relancer la récupération des données blockchain et backend pour le nouveau compte
        await loadBlockchainAndBackendData(accounts[0]);  // Passe le nouveau compte ici
      } else {
        // Si aucun compte n'est connecté
        dispatch({ type: 'SET_ACCOUNT', payload: '' });
        dispatch({ type: 'SET_NFTS', payload: [] });
        dispatch({ type: 'SET_BALANCE', payload: '0' });
        dispatch({ type: 'SET_NFT_BALANCE', payload: '0' });
      }
    },
    [loadBlockchainAndBackendData]  // S'assurer que la fonction est correctement liée
  );
  

  // Initialisation et écoute des événements MetaMask
  useEffect(() => {
    if (window.ethereum) {
      console.log("MetaMask detected, adding listeners");
      addMetaMaskListeners(handleAccountChanged); // Ajoute l'écouteur pour les changements de compte
      handleConnectWallet(); // Appelle handleConnectWallet pour vérifier une connexion au chargement
    } else {
      console.error("MetaMask non détecté. Assurez-vous que MetaMask est installé.");
    }
  
    return () => {
      if (window.ethereum) {
        window.ethereum.removeListener('accountsChanged', handleAccountChanged);
        window.ethereum.removeListener('chainChanged', () => window.location.reload());
      }
    };
  }, [handleConnectWallet, handleAccountChanged]);  // Inclut handleConnectWallet dans les dépendances si elle est utilisée
  

  // Autres fonctions de gestion de l'interface utilisateur
  const toggleAccountMenu = () => dispatch({ type: 'TOGGLE_ACCOUNT_MENU' });

  const handleLogout = () => {
    dispatch({ type: 'SET_ACCOUNT', payload: '' });
    dispatch({ type: 'SET_BALANCE', payload: '0' });
    dispatch({ type: 'SET_NFT_BALANCE', payload: '0' });
    dispatch({ type: 'SET_NFTS', payload: [] });
    dispatch({ type: 'TOGGLE_ACCOUNT_MENU' });
    alert('Vous devez vous déconnecter de MetaMask manuellement.');
  };

  const handleMintSuccess = () => {
    dispatch({ type: 'SET_CHARACTER_MINTED', payload: false });
    dispatch({ type: 'SET_SHOW_MODAL', payload: false });
    handleConnectWallet(); // Recharger les données après mint
  };

  const nextNfts = () => {
    if (state.currentIndex + 2 < state.nftBalance) {
      dispatch({ type: 'SET_CURRENT_INDEX', payload: state.currentIndex + 2 });
    }
  };

  const prevNfts = () => {
    if (state.currentIndex > 0) {
      dispatch({ type: 'SET_CURRENT_INDEX', payload: state.currentIndex - 2 });
    }
  };

  const handleNftClick = (nft) => {
    dispatch({ type: 'SET_SELECTED_NFT', payload: nft });
    dispatch({ type: 'SET_SHOW_MODAL', payload: true });
  };

  const closeDetailModal = () => {
    dispatch({ type: 'SET_SHOW_MODAL', payload: false });
  };

  const closeModal = () => {
    dispatch({ type: 'SET_SHOW_MODAL', payload: false });
    dispatch({ type: 'SET_CHARACTER_MINTED', payload: false });
    dispatch({ type: 'SET_SELECTED_NFT', payload: null });
  };

  const buyCryptoAngelus = () => {
    alert('Achat Crypto Angelus'); // Placeholder
  };

  return (
    <div className="App" style={{ backgroundImage: `url("https://battle-of-angelus.ovh/images/wallpaper.png")` }}>
      <CentralLoading isLoading={state.isLoading} />

      <header className="App-header">
        <div className="text-background">
          <h1>The Battle of Angelus</h1>
          <nav className="navbar">
            <div className="navbar-content">
              <div className="token-info">
                <span>
                  <img
                    src="https://battle-of-angelus.ovh/images/angIco.png"
                    alt="Angelus"
                    className="crypto-icon"
                    onMouseEnter={() => dispatch({ type: 'SET_CRYPTO_TOOLTIP_VISIBLE', payload: true })}
                    onMouseLeave={() => dispatch({ type: 'SET_CRYPTO_TOOLTIP_VISIBLE', payload: false })}
                  />
                  {state.cryptoTooltipVisible && <span className="tooltip">Angelus</span>}
                  {state.balance}
                </span>
                <button className="buy-button" onClick={buyCryptoAngelus}>+</button>
              </div>
              <div className="nft-info">
                <span>
                  <img src="https://battle-of-angelus.ovh/images/persoIco.png" alt="Personnages" className="person-icon" />
                  {state.nftBalance.toString()}
                </span>
              </div>
              <div className="account-info">
                {state.account ? (
                  <button onClick={toggleAccountMenu}>Mon Compte</button>
                ) : (
                  <button onClick={handleConnectWallet}>Se Connecter</button>
                )}
              </div>
            </div>
          </nav>
          {state.isAccountMenuOpen && (
            <div className="account-popup">
              <p>Compte : {state.account}</p>
              <button onClick={handleLogout}>Déconnexion</button>
            </div>
          )}

          <div className="arena-section">
            <h2>Arenes de Combat (Section vide pour le moment)</h2>
          </div>

          <div className="nft-display">
            {state.nftBalance > 0 ? (
              <div className="nft-container">
                {state.nfts.slice(state.currentIndex, state.currentIndex + 2).map((nft, index) => (
                  <div key={index} className={`nft-card ${state.selectedNft?.character_id === nft.character_id ? 'selected-nft' : ''}`} onClick={() => handleNftClick(nft)}>
                    <h4>{nft.name} {nft.gender}</h4>
                    <p>{nft.race} {nft.class}</p>
                    <img src={nft.imageFront} alt={nft.name} style={{ width: '100px' }} />
                  </div>
                ))}
              </div>
            ) : (
              <p>Vous n'avez pas de personnage. Veuillez en créer un !</p>
            )}
          </div>

          {state.nftBalance > 2 && (
            <div className="nft-navigation">
              <button onClick={prevNfts} disabled={state.currentIndex === 0}>&lt; Précédent</button>
              <button onClick={nextNfts} disabled={state.currentIndex + 2 >= state.nftBalance}>Suivant &gt;</button>
            </div>
          )}

          <CreateCharacter
            account={state.account}
            onCharacterCreated={(character) => dispatch({ type: 'SET_CHARACTER', payload: character })}
            generateCount={state.generateCount}
            setGenerateCount={(count) => dispatch({ type: 'SET_GENERATE_COUNT', payload: count })}
            characterMinted={state.characterMinted}
            character={state.character}
            setCharacterMinted={(minted) => dispatch({ type: 'SET_CHARACTER_MINTED', payload: minted })}
            onMintSuccess={handleMintSuccess}
            dispatch={dispatch}
            playerId={state.playerId}
            closeModal={closeModal}
          />
        </div>
      </header>

      {state.showModal && state.selectedNft && (
        <DetailCharacter
          nft={state.selectedNft}
          onClose={closeDetailModal}
          playerId={state.playerId}
          onCharacterSelected={(characterId) => dispatch({ type: 'SET_SELECTED_NFT', payload: characterId })}
          dispatch={dispatch}
        />
      )}
    </div>
  );
}

export default App;
