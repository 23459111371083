import React, { useState, useEffect } from 'react';
import moment from 'moment'; 
import axios from 'axios';
import { url_backend } from '../config';

const DetailCharacter = ({ nft, onClose, playerId, onCharacterSelected, dispatch }) => {
  const [isFlipped, setIsFlipped] = useState(false);
  const [timeRemaining, setTimeRemaining] = useState('');
  const [progress, setProgress] = useState(100);

  // Gérer le retournement de la carte
  const handleCardFlip = () => setIsFlipped(!isFlipped);

  // Calcul du temps restant et de la progression pour le prochain combat
  useEffect(() => {
    const updateCountdown = () => {
      const now = moment();
      const nextCombatTime = moment(nft.nextCombatTime);
      const duration = moment.duration(nextCombatTime.diff(now));

      if (duration.asMilliseconds() > 0) {
        const hours = Math.floor(duration.asHours());
        const minutes = duration.minutes();
        setTimeRemaining(`${hours} heures et ${minutes} minutes`);

        const totalDuration = 4 * 60 * 60 * 1000; // 4 heures en millisecondes
        const elapsed = totalDuration - duration.asMilliseconds();
        setProgress(Math.min(100, (elapsed / totalDuration) * 100));
      } else {
        setTimeRemaining('Prêt pour le combat');
        setProgress(100);
      }
    };

    const interval = setInterval(updateCountdown, 1000);
    return () => clearInterval(interval);
  }, [nft.nextCombatTime]);

  // Fonction pour sélectionner le personnage
  const selectPerso = async () => {
    console.log(playerId)
    console.log(nft.character_id)
    console.log(nft.tokenId)
    console.log(nft)
    if (!playerId || !nft.character_id) {
      alert("Player ID ou character_id manquant !");
      return;
    }
    try {
      const response = await axios.post(`${url_backend}/characters/select`, {
        player_id: playerId,
        character_id: nft.character_id,
      });

      if (response.data.success) {
        alert('Personnage sélectionné avec succès');
        dispatch({ type: 'SET_SELECTED_NFT', payload: nft });
        onCharacterSelected(nft);
        onClose();
      } else {
        alert('Erreur lors de la sélection du personnage');
      }
    } catch (error) {
      console.error('Erreur lors de la sélection du personnage:', error);
      alert('Une erreur est survenue');
    }
  };

  // Fonction pour rendre les aptitudes raciales et les actions de classe
  const renderList = (items, limit = 3) => (
    <ul>
      {items && Object.values(items).slice(0, limit).map((item, index) => (
        item.name && item.points ? (
          <li key={index}>{item.name} : {item.points.toString()}</li>
        ) : null
      ))}
    </ul>
  );

  const selectArmure = () => alert("Sélectionner Armure");
  const selectArme = () => alert("Sélectionner Arme");

  return (
    <div className="modal-container-detail">
      <div className="card-container">
        <div className={`card ${isFlipped ? 'flipped' : ''}`} onClick={handleCardFlip}>
          <div className="card-front">
            <img src={nft.imageFront} alt="Personnage NFT" />
            <div className="character-info">
              <div className='character-header'>
                <h2 className="character-name">{nft.name}</h2>
                <h4 className="character-gender">{nft.gender}</h4>
              </div>
              <div className='character-body'>
                <div className="character-stats-left">
                  <p>{nft.attributes.size.toString()} cm</p>
                  <p>{nft.attributes.weight.toString()} kg</p>
                </div>
                <div className="character-stats-right">
                  <p><strong>Endurance :</strong> {nft.attributes.endurance.toString()}</p>
                  <p><strong>Force :</strong> {nft.attributes.force.toString()}</p>
                  <p><strong>Agilité :</strong> {nft.attributes.agility.toString()}</p>
                  <p><strong>Intelligence :</strong> {nft.attributes.intelligence.toString()}</p>
                  <p><strong>EcoScore :</strong> {nft.attributes.ecoScore.toString()}</p>
                </div>
              </div>
              <div className='character-footer'>
                <h4 className="character-race">{nft.race}</h4>
                <h4 className="character-class">{nft.class}</h4>
              </div>
            </div>
          </div>
          <div className="card-back">
            <img src={nft.imageBack} alt="Fond NFT" />
            <div className="abilities-info">
              <div className='abilities-info-raciale'>
                <h4>Aptitudes raciales :</h4>
                {renderList(nft.raceAbilities)}
              </div>
              <div className='abilities-info-class'>
                <h4>Actions de classe :</h4>
                <div className='abilities-info-class-attack'>
                  <h5>Attaque :</h5>
                  {renderList(nft.classActions.classActionsAttack)}
                </div>
                <div className='abilities-info-class-defense'>
                  <h5>Défense :</h5>
                  {renderList(nft.classActions.classActionsDefense)}
                </div>
                <div className='abilities-info-class-heal'>
                  <h5>Soin :</h5>
                  {renderList(nft.classActions.classActionsHeal)}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      
      {/* Sélection du personnage */}
      <button onClick={selectPerso} className="select-perso">Sélectionner Personnage</button>

      {/* Barre de décompte */}
      <div className="combat-timer">
        <h4>Prochain combat dans : {timeRemaining}</h4>
        <div className="progress-bar-container">
          <div className="progress-bar" style={{ width: `${progress}%` }}></div>
        </div>
      </div>

      {/* Inventaire du personnage */}
      <div className='inventaire-perso'>
        <h4>Inventaire</h4>
        <div className='inventaire_container'>
          <div className='armure-perso' onClick={selectArmure}>
            <h5>Armure</h5>      
            <div className='armure-select'></div>
          </div>
          <div className='arme-perso' onClick={selectArme}>
            <h5>Arme</h5>      
            <div className='arme-select'></div>
          </div>
        </div>        
      </div>

      <button onClick={onClose} className="close-button">Fermer</button>
    </div>
  );
};

export default React.memo(DetailCharacter);
